<template>
	<div>
		<div class="text-center" v-if="isLoading">
			<br /><br />
			<div id="loading"></div>
		</div>

		<div class="my-account" v-if="subscriptionsSelector !== null && !isLoading">
			<div class="header-menu va-row">
				<div class="va-row">
					<div class="flex container-ddh-menu xs12 with-bottom">
						<div class="dropdown-name">
							<h1>
								Clube:
								{{ subscriptionsSelector[subscriptionSelected].characterName }}
								<i class="fa fa-chevron-down"></i>
							</h1>

							<div class="menu-dropdown">
								<a
									v-on:click="setAccount(id, subscriptionItemList.id)"
									:key="id"
									v-for="(subscriptionItemList, id) in subscriptionsSelector"
								>
									{{ subscriptionItemList.characterName }}
								</a>
							</div>
						</div>
					</div>
					<menu-tabs
						:items="[
							{ label: 'Próximos Kits', slug: 'next-kits', isActive: true },
							{
								label: 'Kits já enviados',
								slug: 'delivered-kits',
								isActive: false,
							},
							{
								label: 'Gerenciar Assinatura',
								slug: 'manage-subscribe',
								isActive: false,
							},
						]"
						@scrollTo="scrollParent"
						ref="tabs"
						class="container-ddh-menu"
					></menu-tabs>
				</div>
				<div class="clearfix"></div>
			</div>

			<div class="container-dhh va-row next-kits">
				<div class="flex xs12 no-padding">
					<div class="ui-grid">
						<div class="my-data-table va-row" id="cards">
							<div class="line-item" v-if="subscriptions.length > 0">
								<div
									class="line"
									v-if="subscriptions.length > 0 && !isLoading"
								></div>
								<div class="item-line">
									<div class="va-row">
										<div class="flex xs12 no-padding">
											<h4>Próximos Kits</h4>
										</div>

										<div class="flex xs12 no-padding white-border status-box">
											<div class="body-spacing">
												<div class="va-row">
													<status-bar
														:subscription="subscriptions[0].subscription"
													/>
												</div>
												<div class="clearfix"></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<next-item
								:subscription="subscription"
								:counter="id"
								:key="id"
								v-for="(subscription, id) in subscriptions"
							/>

							<div
								class="line-item active"
								v-if="subscriptions.length === 0 && !isLoading"
							>
								<div class="item-line">
									<div class="va-row">
										<div
											class="
												flex
												xs12
												no-padding
												white-border
												box-status
												empty-state
											"
										>
											<div class="body-spacing">
												<div class="book-main-info">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														width="230"
														viewBox="0 0 230 201"
													>
														<defs>
															<path
																id="b"
																d="M344 258h1040c5.523 0 10 4.477 10 10v335c0 5.523-4.477 10-10 10H344c-5.523 0-10-4.477-10-10V268c0-5.523 4.477-10 10-10z"
															/>
															<filter
																id="a"
																width="108.3%"
																height="124.8%"
																x="-4.2%"
																y="-9.6%"
																filterUnits="objectBoundingBox"
															>
																<feOffset
																	dy="10"
																	in="SourceAlpha"
																	result="shadowOffsetOuter1"
																/>
																<feGaussianBlur
																	in="shadowOffsetOuter1"
																	result="shadowBlurOuter1"
																	stdDeviation="13"
																/>
																<feComposite
																	in="shadowBlurOuter1"
																	in2="SourceAlpha"
																	operator="out"
																	result="shadowBlurOuter1"
																/>
																<feColorMatrix
																	in="shadowBlurOuter1"
																	values="0 0 0 0 0.885930506 0 0 0 0 0.86613143 0 0 0 0 0.965126812 0 0 0 1 0"
																/>
															</filter>
															<path
																id="c"
																d="M.726.092h115.478v168.685H.726z"
															/>
														</defs>
														<g fill="none" fill-rule="evenodd">
															<path fill="#FFF" d="M-744-299H696v1866H-744z" />
															<!-- <g transform="translate(-744 -299)">
                                    <use fill="#000" filter="url(#a)" xlink:href="#b"/>
                                    <path fill="#FFF" stroke="#DFDAE4" stroke-linejoin="square" d="M344 258.5a9.5 9.5 0 0 0-9.5 9.5v335a9.5 9.5 0 0 0 9.5 9.5h1040a9.5 9.5 0 0 0 9.5-9.5V268a9.5 9.5 0 0 0-9.5-9.5H344z"/>
                                </g> -->
															<path
																fill="#E8E2FF"
																d="M42.43 158.33c84.833-18.308 210.025 8.86 177.586 22.025C125.027 218.9-68.06 182.173 42.43 158.33"
															/>
															<g transform="translate(44 4.908)">
																<mask id="d" fill="#fff">
																	<use xlink:href="#c" />
																</mask>
																<path
																	fill="#7E53C1"
																	d="M111.682 164.886l-100.03 3.888a4.705 4.705 0 0 1-4.883-4.518L.73 8.866a4.704 4.704 0 0 1 4.518-4.883L105.278.095a4.705 4.705 0 0 1 4.883 4.52l6.04 155.388a4.704 4.704 0 0 1-4.519 4.883"
																	mask="url(#d)"
																/>
															</g>
															<path
																fill="#6C40B2"
																d="M62.033 171.523a1.712 1.712 0 0 1-1.617-1.654L55.788 27.21a1.71 1.71 0 0 1 1.655-1.766c.96-.048 1.735.709 1.766 1.654l4.628 142.659a1.71 1.71 0 0 1-1.804 1.766"
															/>
															<path
																fill="#241971"
																d="M94.285 86.537c-5.04-.271-8.954-3.852-10.108-9.357a1.713 1.713 0 0 1 3.352-.702c.892 4.274 3.741 6.746 7.615 6.648 3.707-.108 7.853-2.847 8.607-7.873.138-.934.996-1.603 1.945-1.44a1.711 1.711 0 0 1 1.44 1.946 12.51 12.51 0 0 1-11.893 10.79c-.323.009-.643.005-.958-.012M123.686 84.792c-5.04-.27-8.956-3.852-10.108-9.357a1.713 1.713 0 0 1 1.325-2.027c.924-.188 1.832.4 2.027 1.325.894 4.274 3.74 6.767 7.615 6.648 3.707-.108 7.854-2.845 8.608-7.872.138-.933 1.005-1.602 1.945-1.44a1.71 1.71 0 0 1 1.44 1.947 12.508 12.508 0 0 1-11.894 10.788c-.323.009-.643.005-.958-.012M105.195 95.03a1.712 1.712 0 0 1-1.393-2.559c1.402-2.455 3.719-4.001 6.35-4.24 2.305-.215 4.519.663 5.927 2.328a1.714 1.714 0 0 1-2.62 2.207c-.683-.811-1.795-1.231-2.996-1.125-1.504.137-2.848 1.058-3.688 2.53a1.712 1.712 0 0 1-1.58.859"
															/>
															<path
																fill="#FF4547"
																d="M93.874 94.615c.102 2.622-2.422 4.849-5.121 4.953-2.698.105-5.387-1.92-5.489-4.54-.102-2.622 2.841-4.864 5.539-4.97 2.699-.104 4.969 1.936 5.071 4.557M136.878 93.084c.105 2.698-1.468 3.832-4.32 3.943-2.854.11-5.906-.842-6.01-3.542-.106-2.698 2.122-4.975 4.975-5.087 2.853-.11 5.25 1.988 5.355 4.686"
															/>
															<path
																fill="#FFF"
																d="M148.211 16.235L55.44 19.84a2.324 2.324 0 0 1-2.414-2.232l-.128-3.307a2.324 2.324 0 0 1 2.232-2.413l92.773-3.605a2.324 2.324 0 0 1 2.413 2.232l.128 3.307a2.324 2.324 0 0 1-2.232 2.413"
															/>
															<path
																fill="#996CD8"
																d="M67.334 32.987a3.423 3.423 0 0 1 .018-6.838l76.043-3.748c1.892-.104 3.495 1.363 3.587 3.25a3.424 3.424 0 0 1-3.25 3.589l-76.044 3.747a3.216 3.216 0 0 1-.354 0M76.357 44.305a3.425 3.425 0 0 1-.015-6.837l58.57-3.48a3.41 3.41 0 0 1 3.622 3.213 3.425 3.425 0 0 1-3.214 3.623l-58.571 3.48a3.42 3.42 0 0 1-.392.001"
															/>
															<path
																fill="#241971"
																d="M100.192 154.521a2.555 2.555 0 0 1-1.607-.683l-31.832-29.554a2.566 2.566 0 1 1 3.493-3.763l31.832 29.555a2.567 2.567 0 0 1-1.886 4.445M131.246 152.473a2.568 2.568 0 0 1-2.02-3.956l21.599-33.497a2.567 2.567 0 1 1 4.317 2.783L133.544 151.3a2.57 2.57 0 0 1-2.298 1.173M125.155 190.958a8.223 8.223 0 0 1-2.554-.554l-44.623-17.4a2.57 2.57 0 0 1-1.46-3.326 2.57 2.57 0 0 1 3.327-1.46l44.623 17.4a3.027 3.027 0 0 0 2.702-.24 3.037 3.037 0 0 0 1.443-2.3l.613-6.173c.139-1.411 1.365-2.433 2.809-2.301a2.567 2.567 0 0 1 2.3 2.809l-.614 6.173a8.123 8.123 0 0 1-3.866 6.17 8.132 8.132 0 0 1-4.7 1.202M180.567 181.153a8.256 8.256 0 0 1-2.603-.223l-40.34-9.957a2.568 2.568 0 0 1 1.238-4.984l40.34 9.957a3.03 3.03 0 0 0 2.649-.586 3.03 3.03 0 0 0 1.135-2.466l-.182-6.2c-.042-1.417 1.042-2.588 2.49-2.642a2.567 2.567 0 0 1 2.642 2.49l.182 6.202a8.118 8.118 0 0 1-3.044 6.613 8.126 8.126 0 0 1-4.507 1.796"
															/>
															<path
																fill="#241971"
																d="M100.421 150.375c1.414-.915 8.804 1.936 9.458 5.06.61 2.919-5.596.115-5.596.115s2.971 6.369.265 6.638c-2.705.269-3.906-4.35-3.906-4.35s-.483 5.149-2.514 4.42c-3.143-1.13-3.346-10.944 2.293-11.883"
															/>
															<path
																fill="#241971"
																d="M101.421 150.635c-.383-.021-.645.02-.768.099a.419.419 0 0 1-.16.064c-3.383.563-3.986 4.388-4.09 5.548-.238 2.627.585 5.047 1.871 5.508.205.072.374.059.543-.048.791-.502 1.278-2.725 1.398-4.007a.432.432 0 0 1 .393-.388.44.44 0 0 1 .448.319c.013.042 1.141 4.273 3.45 4.032.278-.027.456-.133.576-.344.586-1.031-.416-4.035-1.186-5.69a.425.425 0 0 1 .084-.48.43.43 0 0 1 .481-.09c1.634.74 4.236 1.542 4.894 1.01.05-.04.204-.163.105-.645-.28-1.336-2.183-2.917-4.847-4.03-1.392-.58-2.494-.821-3.192-.858zm-2.986 12.119a1.549 1.549 0 0 1-.452-.095c-1.914-.686-2.672-3.735-2.433-6.391.243-2.684 1.61-5.736 4.722-6.301.94-.498 3.01.045 4.671.738 2.321.968 4.947 2.695 5.353 4.643.173.823-.125 1.263-.405 1.49-.993.788-3.38.055-4.8-.488.59 1.479 1.48 4.183.734 5.49-.256.453-.683.72-1.234.773-1.915.193-3.14-1.656-3.788-3.086-.252 1.124-.71 2.482-1.528 3a1.402 1.402 0 0 1-.84.227zM132.855 150.596c-1.379-1.742-3.904-2.052-5.727-.78-2.353 1.641-5.648 4.323-4.637 5.882 1.53 2.358 5.26-1.58 5.26-1.58s-2.463 7.318-.65 8.244c1.814.924 3.863-6.417 3.863-6.417s.995 4.607 3.332 3.49c1.67-.798.925-5.85-1.44-8.839"
															/>
															<path
																fill="#241971"
																d="M127.774 153.69a.427.427 0 0 1 .383.565c-1.13 3.35-1.777 7.258-.861 7.726.065.033.146.055.28-.002 1.045-.46 2.38-4.016 2.976-6.15a.43.43 0 0 1 .425-.312c.194.028.364.145.406.336.16.733.788 2.743 1.775 3.226.3.147.604.136.953-.03.323-.154.483-.58.559-.91.395-1.705-.376-5.034-2.151-7.277-1.224-1.547-3.486-1.853-5.144-.694-3.433 2.394-5.122 4.374-4.524 5.296.219.34.498.521.852.553l.05.003c1.252.067 3.08-1.558 3.688-2.198a.426.426 0 0 1 .333-.133m-.41 9.18a1.156 1.156 0 0 1-.457-.126c-1.586-.81-.701-4.884-.026-7.258-.873.693-2.085 1.448-3.175 1.39a1.053 1.053 0 0 1-.082-.006c-.615-.057-1.13-.38-1.492-.938-.933-1.439.666-3.615 4.752-6.466 2.033-1.417 4.803-1.037 6.307.867 1.777 2.245 2.815 5.833 2.312 8-.215.93-.675 1.322-1.022 1.49-.58.273-1.17.285-1.7.025-.884-.433-1.468-1.53-1.82-2.44-.6 1.808-1.76 4.793-3.041 5.355a1.223 1.223 0 0 1-.555.107"
															/>
														</g>
													</svg>
													<h3>Seus próximos kits estão sendo processados</h3>
												</div>
												<br />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "my-account",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		created() {
			if (localStorage.isSubscriber !== "true") {
				this.$router.push({ name: "summary" });
			}
		},

		mounted() {
			this.$root.$on("myDataUpdate", () => {
				this.saveAccountInfo();
			});

			if (localStorage.subscriptionSelected) {
				this.subscriptionSelected = localStorage.subscriptionSelected;
			}

			const self = this;

			axios
				.get(process.env.VUE_APP_ROOT_API + "/subscriptions/")
				.then((response) => {
					self.subscriptionsSelector = response.data.data.subscriptions;
					self.getSubscriptionList();
				})
				.catch((e) => {
					self.errors.push(e);
				});
		},

		watch: {
			subscriptionSelected(subscriptionsSelector) {
				localStorage.subscriptionsSelector = subscriptionsSelector;
			},
		},

		data() {
			return {
				subscriptions: [],
				subscriptionsSelector: null,
				subscriptionSelected: 0,

				subscriptionIdSpecial: null,

				isLoading: true,
				// subscription: null,
				// nextKit: null,
				// address: null,
				// paymentsProfile: null,
				// ReadMore: false
			};
		},
		methods: {
			scrollParent(slug) {
				this.$router.push({ name: slug });
				// var elmnt = document.getElementById(slug);
				// this.scrollTo(document.body, elmnt.offsetTop, 600);
			},

			setAccount(position, accountId) {
				this.subscriptions = [];
				this.subscriptionSelected = position;

				const self = this;

				// GET Costumer
				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/subscriptions/" +
							accountId +
							"/stack/next"
					)
					.then((response) => {
						if (response.data.success) {
							self.subscriptions = response.data.data;
							localStorage.subscriptionSelected = position;

							console.log("alto", self.subscriptions);

							self.subscriptions.forEach((element) => {
								if (element.subscription.id === accountId) {
									this.subscriptionIdSpecial = element.subscription;
									// filteredByProduct.push(element)
									// this.isLoading = false
								}
							});

							self.isLoading = false;
						} else {
							self.isLoading = false;
						}
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			getSubscriptionList() {
				const self = this;

				// GET Costumer
				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/subscriptions/" +
							self.subscriptionsSelector[self.subscriptionSelected].id +
							"/stack/next"
					)
					.then((response) => {
						if (response.data.success) {
							self.subscriptions = response.data.data;
							console.log("baixo", self.subscriptions);
							self.isLoading = false;
						} else {
							this.isLoading = false;
						}
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			// changeReadMoreState(state){
			//   this.ReadMore = state;
			// }
		},
	};
</script>
<style lang="scss" scoped>
	.book-main-info {
		text-align: center;
	}
	.empty-state {
		padding-top: 20px !important;
		margin-top: 20px;

		@media (max-width: 990px) {
			padding-top: 30px !important;

			svg {
				max-width: 50%;
				margin-bottom: 15px;
			}
		}

		h3 {
			font-family: Nunito;
			font-size: 20px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #000000;
			margin-top: 20px;

			@media (max-width: 990px) {
				margin-top: 0px;
				margin-bottom: 0px;
			}
		}

		&.next-kit {
			border-radius: 10px;
			box-shadow: 0 10px 26px 0 #e2ddf6;
			background-color: #1ad3d9;
			padding-top: 0px !important;
			padding-bottom: 0px !important;

			@media (max-width: 990px) {
				padding: 0px 0px 0px 0px !important;
			}

			.body-spacing {
				padding-right: 0px;
				padding-top: 0px;
				padding-bottom: 0px;
			}

			.book-main-info {
				margin-bottom: 0px;
			}

			span {
				font-family: Nunito;
				font-size: 18px;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.2;
				letter-spacing: normal;
				color: #ffffff;
			}
			h3 {
				font-family: Nunito;
				font-size: 50px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.2;
				letter-spacing: normal;
				color: #ffffff;
				text-align: left;

				@media (max-width: 1300px) {
					font-size: 25px;
				}

				@media (max-width: 990px) {
					font-size: 26px;
					margin-top: 0px;
					margin-bottom: 30px;
				}
			}

			a {
				border-radius: 40px;
				border: solid 1px #dfdae4;
				background-color: #ffffff;
				background-image: none;
				text-align: center;
				color: #3bd3d6;
			}
		}
	}

	.dropdown-name {
		position: relative;
		float: left;

		.fa {
			border-radius: 25px;
			border: solid 1px #dfdae4;
			background-color: #ffffff;
			font-size: 20px;
			width: 40px;
			height: 40px;
			text-align: center;
			float: right;
			line-height: 38px;
			margin-top: -7px;
			display: inline-block;
			margin-left: 10px;
			color: #1ad3d9;

			@media (max-width: 990px) {
				top: -9px;
				position: relative;
			}
		}

		&:hover {
			.menu-dropdown {
				display: block;
			}
		}
	}
	.menu-dropdown {
		display: none;
		position: absolute;
		top: 90px;
		border-top: 10px solid #907feb;
		border-radius: 10px;
		width: 100%;
		border-radius: 10px;
		-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		background-color: #ffffff;
		padding: 20px;
		padding-top: 0px;
		right: 0;
		z-index: 4;

		a {
			font-family: Nunito;
			font-size: 14px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #68596e;
			border-bottom: 1px solid #dfdae4;
			width: 100%;
			display: block;
			height: 57px;
			position: relative;
			line-height: 60px;
			display: block;
			width: 100%;

			&:hover {
				color: #000;
			}

			&:last-child {
				border-bottom: 0px;
			}

			&:before {
				content: "\F054";
				color: #907feb;
				position: absolute;
				top: 24px;
				width: 4px;
				height: 4px;
				font: normal normal normal 14px/1 FontAwesome;
				right: 9px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:before {
			content: "\F0D8";
			color: #907feb;
			position: absolute;
			top: -20px;
			width: 4px;
			height: 4px;
			font: normal normal normal 14px/1 FontAwesome;
			right: 0;
			left: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.status-box {
		margin-bottom: 20px;

		.body-spacing {
			padding: 20px 20px !important;

			@media (max-width: 990px) {
				padding: 10px 6px !important;
			}
		}
	}

	.header-purple {
		height: 87px;
		border-radius: 10px 10px 0px 0px;
		border: solid 1px #9378f0;
		background-color: #9378f0;
		padding: 20px 16px;

		h1 {
			font-family: Roboto;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin: 0px;
			line-height: 47px;
		}

		.box-date {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.5;
			letter-spacing: normal;
			color: #ffffff;
			height: 40px;
			border-radius: 40px;
			background-color: #7659dc;
			text-align: center;
			display: inline-block;
			padding: 2px 29px;
			margin-top: 3px;
		}
	}
	.learn-paths {
		h1 {
			margin-top: 0px;
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-bottom: 8px;
		}
		p {
			color: #000000;
		}
		.box-home {
			border-radius: 5px;
			border: dashed 2px #9378f0;
			background-color: #ffffff;
			padding: 10px 17px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #000000;

			.house-icon {
				float: left;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				background-color: #9378f0;
				text-align: center;
				padding-top: 6px;
				margin-right: 10px;
				svg {
					path {
						fill: #fff;
					}
				}
			}

			p {
				padding-top: 4px;
				margin-bottom: 0px;
			}
		}
	}

	.line-item {
		width: 100%;

		&.status-box {
			//margin-bottom: 21px;

			.va-row {
				margin-bottom: 0px !important;
			}
		}

		// .va-row{
		//   margin-bottom: 46px !important;
		// }

		&.active {
			.line {
				&:before {
					content: "";
					position: absolute;
					left: -10px;
					top: 38px;
					width: 20px;
					height: 20px;
					border-radius: 25px;
					background-color: #9378f0;
				}
			}
		}

		.line {
			width: 2px;
			height: 100%;
			display: block;
			background-color: #9378f0;
			float: left;
			margin-right: 30px;
			position: relative;

			@media (max-width: 990px) {
				display: none;
			}
		}

		.item-line {
			width: 100%;
		}
	}

	.panel-kit {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;

		.title {
			border-radius: 10px 10px 0px 0px;
			background-color: #f8f7fc;
			padding: 0px 20px;

			h1 {
				font-family: Roboto;
				font-size: 16px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				color: #000000;
				line-height: 40px;
			}
		}
	}

	.book-main-info {
		.btn-change {
			height: 47px;
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 5.5;
			letter-spacing: 0.7px;
			text-align: right;
			color: #4965bc;
			position: absolute;
			right: 0px;
			top: 10px;
		}
	}

	.name-son {
		position: relative;

		span {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #ada1b9;
		}
		h1 {
			font-family: Nunito;
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.3;
			letter-spacing: 2.2px;
			color: #000000;
			margin-top: 10px;
			margin-bottom: 0px;
		}
	}

	h3.next-kit {
		font-family: Nunito;
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.next-kits {
		.btn.btn-change {
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #4965bc;
			background: none;
			border: 0px;
			margin-top: 7px;
		}
	}
	.cover-photo {
		border-radius: 10px;
		overflow: hidden;

		img {
			max-width: 208px;
			float: left;
			width: 100%;
		}
		&.character {
			img {
				border-radius: 10px;
				border: 1px solid #ccc;
				max-width: 181px;
				float: left;
			}
		}
	}

	.product-info {
		h3 {
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
		}
		p {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.36;
			letter-spacing: normal;
			color: #000000;
			margin-bottom: 0px;
		}

		b {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.36;
			letter-spacing: normal;
			color: #000000;
			font-weight: bold;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	.header-menu {
		background-color: white;
		-webkit-box-shadow: 0px 21px 30px -23px #dfdee4;
		box-shadow: 0px 21px 30px -23px #dfdee4;
		border-bottom: 1px solid #dfdae4;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 26px;
				margin-top: 40px;
				margin-bottom: 30px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 20px;
				margin-bottom: 10px;
				margin-top: 30px;
			}
		}
	}
	.white-border {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;
		margin-bottom: 20px;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.dual-inputs {
			padding: 0px 0px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 54%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.address-boxes {
		border-radius: 10px;
		background-color: #ffffff;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 54%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}
</style>
